import React, { useState, useEffect, useContext } from "react"
import { Link, navigate } from "gatsby"
import styled from "styled-components"

import { UserContext } from "../../state/UserContext"
import { postData } from "../../services/server"

import AccountPage from "../../components/layout/AccountPage"

const Projects = styled.table`
    width: 100%;

    tr {
        cursor: pointer;

        &:hover {
            td {
                background-color: #e0e0e0;
            }
        }
    }
    th {
        padding: 5px;
    }
    td {
        padding: 8px;
        background-color: white;
    }
`
const AccountQuotesPage = () => {
  const userContext = useContext(UserContext);

  const [projects, setProjects] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  
  useEffect(
    () => {
        setLoading(true);

        postData("/api/projects/foremail", {email: userContext.email},
            function(result) {
                setProjects(result);
            },
            function(error, status) {
                // if (status == 401 || status == 403)
                //     navigate("/account")
                // else                
                    setError("Error loading data.");
            },
            function() {
                setLoading(false);
            }
        );
    }, 
    []
  );

  return (
    <AccountPage 
        page="projects" 
		breadcrumb={[
			{url: "/", title: "Home"},
			{url: null, title: "Projects"}
        ]}
        secure="registered" 
        loading={loading} 
        error={error}
    >
      { projects ?
          projects.length ? 
            <Projects>
                <thead>
                    <tr>
                        <th>Project #</th>
                        <th>Title</th>
                        <th>Signs</th>
                        <th>Date</th>
                    </tr>
                </thead>
                <tbody>
                {projects.map(project => (
                    <tr key={project.id} onClick={() => navigate("/account/project/?uid=" + project.uid)}>
                        <td className="link">
                            <Link to={"/account/project/?uid=" + project.uid} onClick={() => false}>
                                #{project.id}
                            </Link>
                        </td>
                        <td>
                            { project.title ? project.title : ("Price Request #"+project.id) }
                        </td>
                        <td>{project.sign_count}</td>
                        <td>{project.requested_date_formatted}</td>
                    </tr>
                ))}
                </tbody>
            </Projects>
            :
            <p>No projects found.</p>
        :
            null
      }
    </AccountPage>
  )
}

export default AccountQuotesPage